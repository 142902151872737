































































import { Component, Vue, Watch } from "vue-property-decorator";

import BaseTooltip from "@/components/menu/BaseTooltip.vue";
import SwitchSlider from "@/components/menu/SwitchSlider.vue";
import { Game, State } from "@/store/settings";

@Component({
  components: {
    BaseTooltip,
    SwitchSlider,
  },
})
export default class SettingsKh1 extends Vue {
  settings: State[Game.KH1] = { ...this.$store.state.settings[Game.KH1] };
  show: State[Game.KH1]["show"] = { ...this.$store.state.settings[Game.KH1].show };

  get drag(): boolean {
    return this.$store.state.drag;
  }

  @Watch("settings", { deep: true })
  onSettingsChanged(): void {
    this.$store.commit("settings/setKh1Settings", this.settings);
  }

  @Watch("show", { deep: true })
  onShowChanged(): void {
    this.$store.commit("settings/setKh1Show", this.show);
  }

  toggleDrag() {
    this.$store.commit("toggleDrag");
  }

  reset() {
    this.$store.commit("settings/resetNums");
  }
}
